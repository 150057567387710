import React, { useEffect, useRef, useState } from "react";

import { map, distinctUntilChanged, tap, filter, pairwise } from 'rxjs/operators';

import moment from "moment";

import styled from "styled-components";
import ChartWrapper from "./libs/react-lightweight-chart";
import { formatMoney } from "./WebsocketConsumer";
import { SignalsReversalDataContext } from "./SignalsReversalDataProvider";


// momentjs calculate minutes from a date
function minutesFromNow(date: any) {
  return moment().diff(date, 'minutes');
}
const SignalItem: React.FC<any> = ({ pairs, signal }) => {

  const pair = pairs[signal.pair];

  console.log('PAIR', pair );

  if(!pair) {
    return null;
  }
  
  const background = signal.htf_event === 'touch slow from bottom' ? '#4fc70e5c' : '#fff';
  const volumeFilter = pair.vol_5m && pair.vol_5m > 300000 ? 'flex' : 'none';


  const pairLinkTv = `https://www.tradingview.com/chart/?symbol=BINANCE%3A${signal.pair.replace('/', '')}.P`
  const pairLinkBinance = `https://www.binance.com/en/futures/${signal.pair.replace('/', '')}`

  return (
    <div className="signal-item" style={{ display: volumeFilter, backgroundColor: background }}>
      <div style={{ width: '140px', flexGrow: '1' }} >

        <h3>
          <a href={pairLinkTv} target="_blank">{signal.pair}
          </a>&nbsp;<a href={pairLinkBinance} target="_blank">🔶</a>
        </h3>
      </div>
      <div style={{ flexGrow: '1' }} >{signal.interval}m  {signal.content}</div>
      <div style={{  flexGrow: '1' }} >{minutesFromNow(signal.add_date)} min </div>
      <div style={{textAlign: 'right', flexGrow: '1' }}> {formatMoney(pair.vol_5m)}</div>
    </div>
  )
}

const SignalsReversal: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [signalsData, setSignalsData] = useState<any>(null);
  const { pairs$, signals$ } = React.useContext(SignalsReversalDataContext);

  useEffect(() => {

    const subscription = pairs$.pipe(
      map((pairs: any) => {
        console.log('pairs', pairs);

        const pairsMap = {}
        pairs.forEach((pair: any) => {
          pairsMap[pair.pair] = pair;
        })

        console.log('PAIRS MAP', pairsMap);

        return pairsMap
      }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      setData(value);
    });
    const subscriptionSignals = signals$.pipe(
      // map((signals: any) => {
      //   console.log('SIGNALS', signals );


      //   return signals
      // }),
      // distinctUntilChanged(_.isEqual),
    ).subscribe((value: any) => {

      console.log('VALUE SUB', value);

      setSignalsData(value);
    });

    return () => {
      subscription.unsubscribe();
      subscriptionSignals.unsubscribe();
    };
  }, [pairs$, signals$]);


  useEffect(() => {

  }, []);

  return (
    <>
      <div className="">
        <header className="header">
          <div className="filters">
            filters
          </div>
        </header>
        <div className="content">
          {
            signalsData && data
              ? <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                
                <div className="signals-list">
                  {
                    signalsData.filter((signal:any) => {
                      console.log('sign int', signal.interval );
                      
                      return signal.interval === '15' && ((signal.content === 'closed above blue' && signal.hts_direction === 'down') || (signal.content === 'closed below blue' && signal.hts_direction === 'up'))
                    } ).map((signal: any) => {
                      return <SignalItem key={signal.id} pairs={data} signal={signal} ></SignalItem>
                    })
                  }
                </div>
                <div className="signals-list">
                  {
                    signalsData.filter((signal:any) => {
                      console.log('sign int', signal.interval );
                      
                      return signal.interval === '1' && ((signal.content === 'closed above blue' && signal.hts_direction === 'down') || (signal.content === 'closed below blue' && signal.hts_direction === 'up'))
                    } ).map((signal: any) => {
                      return <SignalItem key={signal.id} pairs={data} signal={signal} ></SignalItem>
                    })
                  }
                </div>
                </div>
              </>
              : (<div>loading...</div>)
          }
        </div>
      </div>
    </>
  );
};

export default SignalsReversal;
