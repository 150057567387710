import React, { useEffect, useRef, useState, createContext } from "react";
import io from "socket.io-client";

import { fromEvent, BehaviorSubject, Subject } from 'rxjs';
import { map, distinctUntilChanged, shareReplay } from 'rxjs/operators';

const PairsDataContext = createContext({
  pairs$: null,
  filter: 'vol_5m > 0',
  changeFilter: (filter: string) => {}
})

const SOCKET_SERVER_URL = process.env.REACT_APP_WS_URL ? process.env.REACT_APP_WS_URL : ''


function PairDataProvider({ children }: any) {

  const [isConnected, setIsConnected] = useState(false);
  const [filter, setFilter] = useState('vol_5m > 500000');
  const $pairsSubject: any = new Subject();

  useEffect(() => {
  
    const socket = io(SOCKET_SERVER_URL, {
      reconnectionAttempts: 10, // Number of reconnection attempts before giving up
      reconnectionDelay: 30000, // Delay between reconnection attempts
    });
  
    // function sendQuery(conditions) {
    //   socket.emit('query', conditions);
    // }

    // socket.on('connect', () => {
    //   setIsConnected(true);
    //   $pairsSubject.next({values: [], ws_status: false});
    //   console.log('Connected to WebSocket');
    // });

    // socket.on('disconnect', () => {
    //   setIsConnected(false);
    //   $pairsSubject.next({values: [], ws_status: false});
    //   console.log('Disconnected from WebSocket');

    // });

    // // // Handle reconnection attempts
    // socket.on('reconnect_attempt', (attemptNumber) => {
    //   console.log(`Reconnection attempt #${attemptNumber}`);
    // });

    // Handle reconnection success
    // socket.on('reconnect', () => {
    //   // setIsConnected(true);
    //   // $pairsSubject.next({values: [], ws_status: true});
    //   console.log('Reconnected to WebSocket');
    // });

    socket.emit("subscribe", JSON.stringify({filter}));

    
    socket.on("pairs-status", (data: any) => {

      const pairs = JSON.parse(data)
      
      if (pairs.length > 0) {
        const pairsConverted = pairs.map((pair: any) => {
          if (pair.last_m5_touch_event) {
            const [event, ts] = pair.last_m5_touch_event.split(':');
            pair.last_m5_event_ts = ts;
            pair.last_m5_event = event;
          }

          return pair;
        })
        
        // .sort((a: any, b: any) => {
        //   if (a.last_m5_event_ts < b.last_m5_event_ts) {
        //     return 1;
        //   }
        //   if (a.last_m5_event_ts > b.last_m5_event_ts) {
        //     return -1;
        //   }
        //   return 0;
        // })

        $pairsSubject.next(pairsConverted);
      }
    });

    return () => {
      socket.disconnect(); // Disconnect from the socket.io server when the component unmounts
    };
  }, [filter]);

  const pairs$ = $pairsSubject.pipe(shareReplay(1))


  const changeFilter = (filter: string) => {
    setFilter(filter);
  
  }
  return (
    <PairsDataContext.Provider value={{pairs$, filter, changeFilter}}>
      {children}
    </PairsDataContext.Provider>
  );
}

export { PairsDataContext, PairDataProvider };