import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import Chart from 'kaktana-react-lightweight-charts'
import moment from 'moment'
import styled from 'styled-components'
import { BrowserRouter } from "react-router-dom"

function ChartPage(props: { pair: any, tf: any }) {
  const { pair, tf } = props
  const [candles, setCandles] = useState([])
  const [sma, setSMA] = useState([])

  const options = {
    alignLabels: true,
    timeScale: {
      color: '#ff9e9e',
      rightOffset: 12,
      barSpacing: 3,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      rightBarStaysOnScroll: true,
      borderVisible: false,
      borderColor: "#fff000",
      visible: true,
      timeVisible: true,
      secondsVisible: true,
    },
    layout: {
      backgroundColor: '#fff',
      textColor: 'rgba(255, 255, 255, 0.8)',
    },
    grid: {
      vertLines: {
        color: 'rgba(197, 203, 206, 0)',
      },
      horzLines: {
        color: 'rgba(197, 203, 206, 0.1)',
      },
    },
    crosshair: {
      // mode: LightweightCharts.CrosshairMode.Normal,
    },
  }

  useEffect(() => {
    async function fetchData() {


      const response = await fetch(`http://localhost:4000/api/get-candles?pair=${pair}&tf=${tf}`)
      let json = await response.json();
      console.log('json', json);
      setCandles(json)

      const response2 = await fetch(`http://localhost:4000/api/get-wilder?pair=${pair}&tf=${tf}`)
      let json2 = await response2.json();
      console.log('json2', json2);
      setSMA(json2)



    }
    fetchData();

  }, [])

  useEffect(() => {
    const handleScroll = () => {

      console.log('window.scrollY', window.scrollY);


    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let data: any = []

  if (candles && candles.length > 0) {
    data = candles.map(candle => {

      const time = parseInt(moment(candle['date']).format('X'))
      return { time, open: candle['open'], high: candle['high'], low: candle['low'], close: candle['close'] }
    })
  }

  let linedata_high: any = []
  let linedata_low: any = []
  let linedata_fast_high: any = []
  let linedata_fast_low: any = []

  if (sma && sma.length > 0) {
    linedata_high = sma.filter((row: any) => (row.input === 'high' && row.period === 144)).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })
    linedata_low = sma.filter((row: any) => row.input === 'low' && row.period === 144).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })

    linedata_fast_high = sma.filter((row: any) => (row.input === 'high' && row.period === 33)).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })
    linedata_fast_low = sma.filter((row: any) => row.input === 'low' && row.period === 33).map(row => {
      const time = parseInt(moment(row['date']).format('X'))
      return { time, value: parseFloat(row['value']) }
    })
  }

  const markers: any = [];

  const time =  moment().subtract(8, 'days').local().unix()



  markers.push({
    time,
    position: 'aboveBar',
    color: 'white',
    shape: 'arrowDown',
  });

  markers.push({
    time,
    position: 'belowBar',
    color: 'white',
    shape: 'arrowUp',
  });

  let series = [
    {
      data,
      markers,
    }
  ]

  let lines = [
    {
      options: {
        color: '#f31616',
        lineWidth: 1,
        lineColor: '#f31616',
        lastValueVisible: false,
        priceLineVisible: false,
      },
      data: linedata_high
    },
    {
      options: {
        color: '#f31616',
        lineWidth: 1,
        lineColor: '#f31616',
        lastValueVisible: false,
        priceLineVisible: false,
      },
      data: linedata_low
    },
    {
      options: {
        color: '#2c62e1',
        lineWidth: 1,
        lineColor: '#2c62e1',
        lastValueVisible: false,
        priceLineVisible: false,
      },
      data: linedata_fast_high
    },
    {
      options: {
        color: '#2c62e1',
        lineWidth: 1,
        lineColor: '#2c62e1',
        lastValueVisible: false,
        priceLineVisible: false,
      },
      data: linedata_fast_low
    }
  ]

  return (
    <div>
      {series[0]?.data?.length > 0 &&
        <ChartWrapper>
          <Chart options={options}
            candlestickSeries={series}
            lineSeries={lines}
            autoWidth height={420} />
        </ChartWrapper>
      }
    </div>
  );
}

const ChartWrapper = styled.div`
  flex: 1;
  width: 50vw;
  height: 400px;
  margin: 10px auto;
`

export default ChartPage;
